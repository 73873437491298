import React, { useState, useEffect } from 'react';
import {BrowserRouter, Route, Redirect } from 'react-router-dom';
import Utils from '../../utils';
import {VERSION_SESSION} from '../../config';

import LoginForm from '../account/LoginForm';
import Dashboard from '../dashboard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';



function App() {
  
const [login, setLogin] = useState(false);
const [loading, setLoading] = useState(false);
const [load, setLoad] = useState(false);
const [company, setCompany] = useState(null);

const handleCloseSession = (event) => {
  localStorage.clear();
  setCompany(null);
  setLogin(false);

}

useEffect(() => {
  if(localStorage.getItem('token_user')){
    if(VERSION_SESSION!==localStorage.getItem('VERSION_SESSION')){
      localStorage.clear();
      setLoad(true);
    }else{
      setLoading(true);
      Utils.Petition.get(
        '/users/current',
        response=> {
          setLogin(true);
          localStorage.setItem('menu', response.data.rol.permisos);
          localStorage.setItem('company', response.data.user._id);
          localStorage.setItem('firstname', response.data.user.firstname);
        },
        err => localStorage.clear(),
        ()=> {
          setLoading(false)
          setLoad(true);
        }
      );
    }
    
    
  }else{
    setLoad(true);
  }

},[]);

  return (
    
      <div className="App">
        {loading && <div className="loading"></div>}
        {
          load &&
          <BrowserRouter>
          { login ? <Redirect to="/dashboard" />:( <Redirect to="/" />) }
          <Route path="/dashboard" component={() => <Dashboard handleCloseSession={handleCloseSession} company={company} setCompany={setCompany} />} />
          <Route path="/" exact component={() => <LoginForm changeLogin={setLogin} />} />
        </BrowserRouter>
        }
      </div>
    
  );
}

export default App;
