import React, {useEffect, useState} from 'react';
import {FaTrash} from 'react-icons/fa';

function Agents({changeTitle}){
    const [formAssign, setFormAssign] = useState({
        agentId: '1',
        channelBotId: '1',
    });
    const [currentId, SetCurrentId] = useState(3);
    const [form, setForm] = useState({
        firstname: '',
        lastname: '',
        email:'',
        department:'',
    });
    const [channelBots, setChannelBots] = useState([
        {
            id:'1',
            name: 'Atención secundaria a clientes'
        },
        {
            id:'2',
            name:"Central de soporte",
        }
    ]);

    const [agents, setAgents] = useState([
        {
            id:'1',
            firstname: 'Juan',
            lastname:'Arango',
            email:'juan.arango@triario.com',
            department:'Diseño'
        },
        {
            id:'2',
            firstname: 'Diego',
            lastname:'Atehortua',
            email:'diego.artheortua@nmksoftware.co',
            department:'Ventas y servicio al cliente'
        },
        
    ]);
    const [list, setList] = useState([
        {
            agentId:'2',
            channelBotId:'1',
            timestamp: new Date(),
        }
    ]);
    useEffect(() => {
        changeTitle('Gestión de agentes');
    });
    const handleSubmit = function(event){
       event.preventDefault();
        setAgents([...agents, {
            id:''+currentId,
            firstname: form.firstname,
            lastname:form.lastname,
            email:form.email,
            department:form.department,
        }]);
        SetCurrentId(currentId + 1);
        setForm({
            firstname: '',
            lastname: '',
            email:'',
            department:'',
        });

    };
    const handleSubmitAssign = function(event){
        event.preventDefault();
        setList([...list, {
            agentId:formAssign.agentId, 
            channelBotId:formAssign.channelBotId, 
            timestamp: new Date(), 
        }]);
    };
    const getName= function(id, _array){
        for(let i in _array){
            if(_array[i].id===id) return _array[i].name;
        }
        return '';
    }
    const getNameFullName = function(id, _array){
        for(let i in _array){
            if(_array[i].id===id) return _array[i].firstname+ " "+_array[i].lastname;
        }
        return '';
    }
    const handleForm = function(event){
        setForm({...form, [event.target.id]:  event.target.value });
    }
    const handleAssignForm = function(event){
        setFormAssign({...formAssign, [event.target.id]:  event.target.value });
    }
    const handleDeleteItem = function(event){
        const target = event.target;
        const id = target.parentNode.parentNode.getAttribute('data-id');
        let newList = list.slice();
        newList.splice(parseInt(id),1);
        setList(newList);
    }
    return (
        <>
            <div className="card shadow mb-4">
                <div className="card-header">
                    Nuevo agente
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div class="row">
                            <div className="col-md-6">   
                                <div className="form-group">
                                    <label htmlFor="firstname" >Nombre</label>
                                    <input type="text" required className="form-control" id="firstname" onChange={handleForm} value={form.firstname} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastname" >Apellido</label>
                                    <input type="text" required className="form-control" id="lastname" onChange={handleForm} value={form.lastname} />
                                </div>
                                
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="email" >Email</label>
                                    <input type="email" required className="form-control" id="email" onChange={handleForm} value={form.email} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="department" >Área/Departamento</label>
                                    <input type="text" required className="form-control" id="department" onChange={handleForm} value={form.department} />
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-info mb-2">Agregar</button>
                    </form>
                </div>
            </div>

            <div className="card shadow mb-4">
                <div className="card-header">
                    Asignar nuevo
                </div>
                <div className="card-body">
                    <form className="form-inline" onSubmit={handleSubmitAssign}>
                        
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="channelBotId" >Bots configurados</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select required className="form-control" id="channelBotId" onChange={handleAssignForm} >
                                {channelBots.map((item, i) =>
                                    <option key={i} value={item.id}>{item.name}</option>
                                )};
                            </select>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="agentId" >Agente</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select required className="form-control" id="agentId" onChange={handleAssignForm} >
                                {agents.map((item, i) =>
                                    <option key={i} value={item.id}>{item.firstname+" "+item.lastname}</option>
                                )};
                            </select>
                        </div>
                        
                        <button type="submit" className="btn btn-info mb-2">Asignar</button>
                    </form>
                </div>
            </div>

            <div className="card shadow mb-4">
                <div className="card-header">
                    Agentes agregados
                </div>
                <div className="card-body">
                <table className="table table-bordered small-p">
                        <thead className="thead-dark">
                            <tr>
                                <th>Nombre</th>
                                <th>Bot</th>
                                <th>Fecha creación</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                                {list.map((item, i) =>
                                    <tr key={i}>
                                        <td>{getNameFullName(item.agentId, agents)}</td>
                                        <td>{getName(item.channelBotId, channelBots)}</td>
                                        <td>
                                            {
                                                item.timestamp.getDate()+
                                                '-'+item.timestamp.getMonth()+
                                                "-"+item.timestamp.getFullYear()+
                                                " "+item.timestamp.getHours()+
                                                ":"+item.timestamp.getMinutes()+
                                                ":"+item.timestamp.getSeconds()
                                            }
                                        </td>
                                        <td data-id={i}><FaTrash onClick={handleDeleteItem} /></td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    {list.length === 0 && <i>No se ha agregado ninguno</i>}
                </div>
            </div>
        </>
    );
}

export default Agents;