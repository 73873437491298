import React, {useEffect, useState} from 'react';
import Utils from '../../../utils';
import AnyChart from 'anychart-react';

function Graphics({changeTitle}){
    
    const [dataG, setDataG] = useState();
    const [list, setList] = useState([]);

    const handleSubmit = function(event){
        event.preventDefault();
        Utils.Petition.post(
            '/reports/conversationsbyinbox',{
                "project":"urbansa"
            },
            response=> {
                let startDate = document.getElementById("startDate").value;
                let endDate = document.getElementById("endDate").value;
                let _status = document.getElementById("estado").value;
                let count = [];
                let _count = "";
                 for (let data in response.json){
                    for(let x in response.json[data]){
                        if(x !== "its_mine" ){
                            for(let y in response.json[data][x]){
                                Utils.Petition.post(
                                    '/reports/starts',{
                                        "sid":response.json[data][x][y][response.json[data][x][y].its_mine].sid
                                    },status=> {
                                        var _its_mine = response.json[data].its_mine;
                                        _its_mine=_its_mine.toLowerCase().charAt(0).toUpperCase() + _its_mine.slice(1)
                                        var timestamp=response.json[data][x][y][response.json[data][x][y].its_mine].time
                                        var date = new Date(timestamp);
                                        var startDates= new Date(startDate);
                                        var endDates= new Date(endDate);
                                        var cleanDate=date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
                                        if(startDates > endDates){
                                            alert("La fecha de fin tiene que ser mayor")
                                        }else
                                        if((startDates <= date && endDates>=date )||(startDate==="" && endDate==="" )){
                                            
                                            if(_status === status.estado){
                                                if(typeof count[_its_mine.toLowerCase()] === "undefined"){
                                                    count[_its_mine.toLowerCase()] = 1
                                                    _count+=_its_mine+","+1+`\n`;
                                                }
                                                else{
                                                    _count=_count.replace(_its_mine+","+1+"\n", _its_mine+","+(count[_its_mine.toLowerCase()]+1)+"\n")
                                                    count[_its_mine.toLowerCase()] =  count[_its_mine.toLowerCase()]+1;
                                                }
                                            }else
                                            if(_status === ""){
                                                if(typeof count[_its_mine.toLowerCase()] === "undefined"){
                                                    count[_its_mine.toLowerCase()] = 1
                                                    _count+=_its_mine+","+1+`\n`;
                                                }
                                                else{
                                                    _count=_count.replace(_its_mine+","+1+"\n", _its_mine+","+(count[_its_mine.toLowerCase()]+1)+"\n")
                                                    count[_its_mine.toLowerCase()] =  count[_its_mine.toLowerCase()]+1;
                                                }
                                            }
                                            
                                        }else
                                        if((startDate !== "" && endDate==="") || (startDate === "" && endDate!=="") ){
                                            alert("Escoja la fecha")
                                        }
                                        setDataG(_count)
                                })
                            }    
                        }
                    }
                }
            },
        );
    }


    useEffect(() => {
        var objeto=[]
        Utils.Petition.post(
        '/reports/conversationsbyinbox',{
            "project":"urbansa"
        },
            response=> {
                let option=[]
                for(let data in response.json){
                    let its_mine=response.json[data]['its_mine']
                    its_mine=its_mine.toLowerCase().charAt(0).toUpperCase() + its_mine.slice(1)
                    if(option[its_mine] !== "a"){
                        option[its_mine]="a"
                        objeto.push({
                            id:its_mine,
                            name:its_mine,
                            active:true
                        })
                    }
                }
                setList(objeto)
            },);

    },[]);

    return (
        <>
           <div className="card shadow mb-4">
                <div className="card-header">
                    Ver reporte
                </div>
                <div className="card-body">
                    <form className="form-inline" onSubmit={handleSubmit}>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="startDate" >Estado</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select className="form-control" id="estado"  >
                                <option value=""></option>
                                <option value="abierto">Abierto</option>
                                <option value="cerrado">Cerrado</option>
                            </select>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="startDate" >Inicio</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <input type="date"  id="startDate"  className="form-control"/>
                        </div>

                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="endDate" >Fin</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <input type="date"  id="endDate" className="form-control"/>
                        </div>
                        <button type="submit" className="btn btn-info mb-2">Consultar</button>
                    </form>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-body">
                    {
                        dataG &&
                        <AnyChart
                            id="pieChart"
                            width={800}
                            height={600}
                            type="pie"
                            data={dataG}
                            title="% Conversaciones que nunca recibieron respuesta"
                        />
                    }
                </div>
            </div>
        </>
    );
};

export default Graphics;