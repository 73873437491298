import React, {useEffect, useState} from 'react';
import {FaTrash} from 'react-icons/fa';
import Utils from '../../../utils';

function AdminFiles({changeTitle}){

    const [table, setTable] = useState([]);
    const [message, setMessage] = useState('');
    const [fileUser, setFileUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleDeleteFile = function(event){
        const target = event.target;
        const id = target.parentNode.parentNode.getAttribute('data-id');
        Utils.Petition.delete(
            `/files/${id}`,
            (response)=>{
                target.parentNode.parentNode.parentNode.remove();
            },
            (_err)=>{
                console.log(_err);
            }
        );
    }
    const handleFile = function(event){
        const files = event.target.files;
        if(files){
            setFileUser(files[0]);
        }else{
            setFileUser(null);
        }
    }
    const handleSubmit = function(event){
        event.preventDefault();
        setLoading(true);
        setMessage('');
        Utils.Petition.postFile(
            '/files/upload',
            {
                user: localStorage.getItem('company'),
                fileUser,
            },
            response => {
                
                Utils.Petition.get(
                    `/files/${localStorage.getItem('company')}`,
                    (response)=>{
                        setTable(response.data);
                    }
                );
            },
            err => {
                if(err.response && err.response.data && err.response.data.message === 'Extension not valid') setMessage('Extensión no válida');
                else setMessage('Ocurrió un error en el servicio, por favor intente de nuevo más tarde');
            },
            ()=> setLoading(false),
        );

    }
    useEffect(() => {
        changeTitle('Administrador de archivos');

        Utils.Petition.get(
            `/files/${localStorage.getItem('company')}`,
            (response)=>{
                setTable(response.data);
            }
        );

    },[]);


    return (
        <>
            <div className="card shadow mb-4">
                {loading && <div className="loading"></div> }
                <div className="card-header">
                    Nuevo archivo
                </div>
                <div className="card-body">
                    <form className="form-inline" onSubmit={handleSubmit}>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="file" >Archivo</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <input type="file" required className="form-control" id="file" onChange={handleFile} />
                        </div>
                        
                        <button type="submit" className="btn btn-info mb-2">Cargar</button>
                    </form>
                    <div className={`alert ${message ? "alert-danger" : ""}`}>{message}</div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header">
                    Archivos cargados
                </div>
                <div className="card-body">
                    
                        
                    <table className="table table-bordered small-p">
                        <thead className="thead-dark">
                            <tr>
                                <th>ID</th>
                                <th>Tipo</th>
                                <th>Nombre</th>
                                <th>Fecha</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                                {table.map((item, i) =>
                                    <tr key={i}>
                                        <td>{item.id}</td>
                                        <td>{item.type}</td>
                                        <td>{item.name}</td>
                                        <td>{item.timestamp}</td>
                                        <td data-id={item.id}><FaTrash onClick={handleDeleteFile} /></td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    {table.length === 0 && <i>No se encontraron archivos</i>}
                    
                    
                </div>
            </div>
        </>
    );
}
export default AdminFiles;