import axios from 'axios';
import {API_URL,VERSION_SESSION} from '../config';

 const Petition = {
    publicGet: (url, callback, fallback, _finally)=> {
        axios.get(
            API_URL+url,
            {responseType: 'json'}
        )
        .then((result)=>{
            callback(result.data);
        })
        .catch((err)=>{
            
            //Add more code all fallbacks

            if(fallback) fallback(err);
        })
        .finally(()=>{
            if(_finally) _finally();
        });

    },
    publicPost: (url, data, callback, fallback, _finally)=> {
        axios.post(
            API_URL+url,
            data,
            {responseType: 'json'}
        )
        .then((result)=>{
            callback(result.data);
        })
        .catch((err)=>{
            
            //Add more code all fallbacks

            if(fallback) fallback(err);
        })
        .finally(()=>{
            if(_finally) _finally();
        });
    },
    get: (url, callback, fallback, _finally)=> {
        axios.get(
            API_URL+url,
            {
                responseType: 'json',
                headers: {
                    Authorization: 'Bearer '+localStorage.getItem('token_user')
                }
                
            }
        )
        .then((result)=>{
            callback(result.data);
        })
        .catch((err)=>{
            
            //Add more code all fallbacks

            if(fallback) fallback(err);
        })
        .finally(()=>{
            if(_finally) _finally();
        });
    },
    delete: (url, callback, fallback, _finally)=> {
        axios.delete(
            API_URL+url,
            {
                responseType: 'json',
                headers: {
                    Authorization: 'Bearer '+localStorage.getItem('token_user')
                }
                
            }
        )
        .then((result)=>{
            callback(result.data);
        })
        .catch((err)=>{
            
            //Add more code all fallbacks

            if(fallback) fallback(err);
        })
        .finally(()=>{
            if(_finally) _finally();
        });
    },
    post: (url, data, callback, fallback, _finally)=> {
        axios.post(
            API_URL+url,
            data,
            {
                responseType: 'json',
                headers: {
                    Authorization: 'Bearer '+localStorage.getItem('token_user')
                }
            }
        )
        .then((result)=>{
            callback(result.data);
        })
        .catch((err)=>{
            
            //Add more code all fallbacks

            if(fallback) fallback(err);
        })
        .finally(()=>{
            if(_finally) _finally();
        });
    },
    postFile:(url,data,callback, fallback, _finally) => {

        let _data = new FormData();
        for(let i in data){
            _data.append(i, data[i]);
        }
        

        axios.post(
            API_URL+url,
            _data,
            {
                responseType: 'json',
                headers: {
                    'Content-Type': 'multipart/form-data;',
                    'Authorization': 'Bearer '+localStorage.getItem('token_user')
                }
                
            }
        )
        .then((result)=>{
            callback(result.data);
        })
        .catch((err)=>{
            
            //Add more code all fallbacks

            if(fallback) fallback(err);
        })
        .finally(()=>{
            if(_finally) _finally();
        });
    }

}
export default Petition;