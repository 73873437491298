import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Utils from '../../utils';
function Topbar({handleCloseSession, company, setCompany}) {

    let history = useHistory();
    const [firstname, setFirstname] = useState('');
    
    const localHandleCloseSession = function(){
        handleCloseSession();
        history.push('/');
    }
    useEffect(() => {
        setFirstname(localStorage.getItem('firstname'));
    }, []);
    
    return (
        
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            
            <div>Hola <strong>{ firstname }</strong></div>
            <div className=" ml-auto">
                <button className="btn btn-light" onClick={localHandleCloseSession}>Cerrar sesión</button>
            </div>
        </nav>


    );
}
export default Topbar;