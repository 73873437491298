import React, {useEffect, useState} from 'react';
import {FaTrash} from 'react-icons/fa';

function ChannelsBots({changeTitle}){
    const [form, setForm] = useState({
        name: "",
        botId: '1',
        channelId: '1',
    });
    const [channels, setChannels] = useState([
        {
            id:'1',
            name: 'WhatsApp'
        },
        {
            id:'2',
            name: 'Facebook'
        },
        {
            id:'3',
            name: 'Instagram'
        }
    ]);

    const [bots, setBots] = useState([
        {
            id:'1',
            name: 'Bot Alicia Clientes'
        },
        {
            id:'2',
            name: 'SupportBot Inc'
        },
        {
            id:'3',
            name: 'EmpleadoBot'
        }
    ]);
    const [list, setList] = useState([
        {
            name:"Atención secundaria a clientes",
            botId:'1',
            channelId:'1',
            timestamp: new Date(),
        },
        {
            name:"Central de soporte",
            botId:'2',
            channelId:'1',
            timestamp: new Date(),
        }
    ]);
    useEffect(() => {
        changeTitle('Asignador de canales y bots');
    });
    const handleSubmit = function(event){
        event.preventDefault();
        setList([...list, {
            name:form.name, 
            botId:form.botId, 
            channelId:form.channelId, 
            timestamp: new Date(), 
        }]);

        setForm({
            ...form,
            name: "",
        });

    };
    const getName= function(id, _array){
        for(let i in _array){
            if(_array[i].id===id) return _array[i].name;
        }
        return '';
    }
    const handleForm = function(event){
        setForm({...form, [event.target.id]:  event.target.value });
    }
    const handleDeleteItem = function(event){
        const target = event.target;
        const id = target.parentNode.parentNode.getAttribute('data-id');
        let newList = list.slice();
        newList.splice(parseInt(id),1);
        setList(newList);
    }
    return (
        <>
            <div className="card shadow mb-4">
                <div className="card-header">
                    Agregar nuevo
                </div>
                <div className="card-body">
                    <form className="form-inline" onSubmit={handleSubmit}>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="name" >Nombre</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <input type="text" required className="form-control" id="name" onChange={handleForm} value={form.name} />
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="channelId" >Canal</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select required className="form-control" id="channelId" onChange={handleForm} >
                                {channels.map((item, i) =>
                                    <option key={i} value={item.id}>{item.name}</option>
                                )};
                            </select>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="botId" >Bot</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select required className="form-control" id="botId" onChange={handleForm} >
                                {bots.map((item, i) =>
                                    <option key={i} value={item.id}>{item.name}</option>
                                )};
                            </select>
                        </div>
                        
                        <button type="submit" className="btn btn-info mb-2">Asignar</button>
                    </form>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header">
                    Bots asignados
                </div>
                <div className="card-body">
                <table className="table table-bordered small-p">
                        <thead className="thead-dark">
                            <tr>
                                <th>Nombre</th>
                                <th>Canal</th>
                                <th>Bot</th>
                                <th>Fecha creación</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                                {list.map((item, i) =>
                                    <tr key={i}>
                                        <td>{item.name}</td>
                                        <td>{getName(item.channelId, channels)}</td>
                                        <td>{getName(item.botId, bots)}</td>
                                        <td>
                                            {
                                                item.timestamp.getDate()+
                                                '-'+item.timestamp.getMonth()+
                                                "-"+item.timestamp.getFullYear()+
                                                " "+item.timestamp.getHours()+
                                                ":"+item.timestamp.getMinutes()+
                                                ":"+item.timestamp.getSeconds()
                                            }
                                        </td>
                                        <td data-id={i}><FaTrash onClick={handleDeleteItem} /></td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    {list.length === 0 && <i>No se ha agregado ninguno</i>}
                </div>
            </div>
        </>
    );
}

export default ChannelsBots;