import React, {useEffect, useState} from 'react';
import Utils from '../../../utils';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import AnyChart from 'anychart-react';

function ReportIngresos({changeTitle}){
    
    const [dataTable,setDataTable] = useState();
    const [dataTable2,setDataTable2] = useState();
    const [filterProject,setFilterProject] = useState();
    const [list, setList] = useState([]);
    const [graphic1, setGraphic1] = useState();
    const [graphic2, setGraphic2] = useState();
    const [showCard1, setShowCard1] = useState(false);
    const [showCard2, setShowCard2] = useState(false);

    const handleShow1 = function(e){
        if(showCard1){setShowCard1(false);}else{setShowCard1(true);}
    }
    const handleShow2 = function(e){
        if(showCard2){setShowCard2(false);}else{setShowCard2(true); }
    }
    const  handleSubmit = async function(event){
        event.preventDefault();
        let _data = []
        _data['vis']=[]
        _data['no_vis']=[]
        let _dataTable =[]
        let _dataTable2 =[]
        let _count=""
        let _count2=""
        let select = document.getElementById("reportId").value;
        setFilterProject(select)

        const resp = await axios.post(`http://52.73.114.253:3003/api/v1/reports/ingresos`);
        for(let i in resp.data.data){
            if(typeof resp.data.data[i]['properties'] !== 'undefined' && typeof resp.data.data[i]['properties'].nivel_de_ingresos___no_vis !== 'undefined'){
                if(typeof resp.data.data[i]['properties'].nivel_de_ingresos___no_vis.value !== 'undefined'){
                    let value=resp.data.data[i]['properties'].nivel_de_ingresos___no_vis.value
                    if(typeof _data['no_vis'][value] === "undefined"){
                        _data['no_vis'][value]=1
                        _count+=value+","+1+`\n`;
                    }
                    else{
                        _data['no_vis'][value]=_data['no_vis'][value]+1;
                        _count=_count.replace(value+","+1+"\n", value+","+(_data['no_vis'][value])+"\n")
                    }
                }
            }else
            if(typeof resp.data.data[i]['properties'] !== 'undefined' && typeof resp.data.data[i]['properties'].nivel_de_ingresos___vis !== 'undefined'){
                if(typeof resp.data.data[i]['properties'].nivel_de_ingresos___vis.value !== 'undefined'){
                    let value=resp.data.data[i]['properties'].nivel_de_ingresos___vis.value
                    if(typeof _data['vis'][value] === "undefined"){
                        _data['vis'][value]=1
                        _count2+=value+","+1+`\n`;
                    }
                    else{
                        _data['vis'][value]=_data['vis'][value]+1;
                        _count2=_count2.replace(value+","+1+"\n", value+","+(_data['vis'][value])+"\n")
                    }
                }
            }
        }
        for(let x in _data){
            for(let y in _data[x]){
                if(x=="vis"){
                    _dataTable.push({range:y.replace('_',"  "),persons:_data[x][y]})
                }else{
                    _dataTable2.push({range:y.replace('_',"  "),persons:_data[x][y]})
                } 
            }
        }
        setGraphic1(_count)
        setGraphic2(_count2)
        setDataTable({
            columns: [
            {
                label: 'Rango de ingresos',
                field: 'range',
                width: 150,
                attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Name',
                },
            },
            {
                label: 'Cantidad de personas',
                field: 'persons',
                width: 270,
            },
            ],
            rows: _dataTable,
        }) 
        setDataTable2({
            columns: [
            {
                label: 'Rango de ingresos',
                field: 'range',
                width: 150,
                attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Name',
                },
            },
            {
                label: 'Cantidad de personas',
                field: 'persons',
                width: 270,
            },
            ],
            rows: _dataTable2,
        })
        console.log(_count)   
    }
  
    useEffect(() => {
        var objeto=[]
        let c=0
        Utils.Petition.post(
            '/reports/conversationsbyinbox',{
                "project":"urbansa"
            },
            response=> {
                let option=[]
                for(let data in response.json){
                    let its_mine=response.json[data]['its_mine']
                    its_mine=its_mine.toLowerCase().charAt(0).toUpperCase() + its_mine.slice(1)
                    if(option[its_mine] !== "a"){
                        option[its_mine]="a"
                        objeto.push({
                            id:its_mine,
                            name:its_mine,
                            active:true
                        })
                    }
                }
                setList(objeto)
            },
        );
    },[]);

    return (
        <>
           <div className="card shadow mb-4">
                <div className="card-header">
                    Ver reporte
                </div>
                <div className="card-body">
                    <form className="form-inline" onSubmit={handleSubmit}>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="reportId" >Proyecto</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select required className="form-control" id="reportId"  >
                                {list.map((item, i) =>
                                    <option key={i} value={item.id} disabled={!item.active}>{item.name}</option>
                                )};
                            </select>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="startDate" >Estado</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select className="form-control" id="estado"  >
                                <option value=""></option>
                                <option value="abierto">Abierto</option>
                                <option value="cerrado">Cerrado</option>
                            </select>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="startDate" >Inicio</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <input type="date"  id="startDate"   className="form-control"/>
                        </div>

                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="endDate" >Fin</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <input type="date"  id="endDate"  className="form-control"/>
                        </div>
                        <button type="submit" className="btn btn-info mb-2">Consultar</button>
                    </form>
                </div>
            </div>
            <div className="card shadow mb-4">
                {
                    dataTable &&
                        <div className="card-header" onClick={handleShow2}>
                            Nivel de ingresos vis
                        </div>
                }
                {
                    showCard2 && 
                    <div className="card-body">
                        {
                            dataTable && filterProject && 
                            <>
                                <center>
                                    <AnyChart
                                        id="pieChart"
                                        width={800}
                                        height={600}
                                        type="pie"
                                        data={graphic2}
                                        title=""
                                    />
                                </center>
                                
                                <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={dataTable} searchTop searchBottom={false}/>
                                
                            </>
                            
                        }
                    </div>
                }
                
            </div>
            <div className="card shadow mb-4">
                {
                    dataTable2 &&
                        <div className="card-header" onClick={handleShow1}>
                            Nivel de ingresos no vis
                        </div>
                }
                {
                    showCard1 && 
                    <div className="card-body">
                        {
                             filterProject && 
                            <>
                                <center>
                                    <AnyChart
                                        id="pieChart2"
                                        width={800}
                                        height={600}
                                        type="pie"
                                        data={graphic1}
                                        title=""
                                    />
                                </center>
                                
                                <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={dataTable2} searchTop searchBottom={false}/>
                                
                            </>

                        }
                    </div>
                }
            </div>
        </>
    );
};

export default ReportIngresos;