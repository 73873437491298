import React, {useEffect, useState} from 'react';
import Utils from '../../../utils';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';

function ReportSells({changeTitle}){
    
    const [dataTable,setDataTable] = useState();
    const [dataTable2,setDataTable2] = useState();
    const [filterProject,setFilterProject] = useState();
    const [list, setList] = useState([]);
    const [showCard1, setShowCard1] = useState(false);
    const [showCard2, setShowCard2] = useState(false);

    const handleShow1 = function(e){
        if(showCard1){setShowCard1(false);}else{setShowCard1(true);}
    }
    const handleShow2 = function(e){
        if(showCard2){setShowCard2(false);}else{setShowCard2(true); }
    }
    const handleSubmit = async function(event){
        event.preventDefault();
        let object = []
        let object2 = []
        const resp = await axios.get(`http://52.73.114.253:3003/api/v1/room/virtual`);
        
        Utils.Petition.post(
            '/reports/conversationsbyinbox',{
                "project":"urbansa"
            },
            async response=> {
                let select = document.getElementById("reportId").value;
                let startDate = document.getElementById("startDate").value;
                let endDate = document.getElementById("endDate").value;
                let _status = document.getElementById("estado").value;
                let _dataTable = [];
                let _dataTable2 = [];
                setDataTable([])
                setDataTable2([])
                setFilterProject(select)
                let _data=[]
                let _data2=[]
                for(let data in response.json){
                    for(let x in response.json[data]){
                        if((x !== "its_mine" && response.json[data]['its_mine'].toLowerCase() === select.toLowerCase())){
                            for(let y in response.json[data][x]){
                                if(x.toLowerCase() === select.toLowerCase()){
                                    if(typeof response.json[data][x][y][response.json[data][x][y].its_mine] !== "undefined"){
                                        let asesor = response.json[data][x][y][response.json[data][x][y].its_mine].assessor
                                        const status = await axios.post(`http://52.73.114.253:3003/api/v1/reports/twilio`,{
                                            "sid":response.json[data][x][y][response.json[data][x][y].its_mine].sid
                                        });
                                        var timestamp=response.json[data][x][y][response.json[data][x][y].its_mine].time
                                        var date = new Date(timestamp);
                                        var startDates= new Date(startDate);
                                        var endDates= new Date(endDate);
                                        if(startDates > endDates){
                                            alert("La fecha de fin tiene que ser mayor")
                                        }else
                                        if((startDates <= date && endDates>=date)||(startDate==="" && endDate==="")){
                                            if(_status === status.data.estado){
                                                for(let i in resp.data.data){
                                                    if(resp.data.data[i].email === asesor){
                                                        if(typeof _data[asesor] === "undefined"){
                                                            _data[asesor]=1
                                                        }
                                                        else{
                                                            _data[asesor]=_data[asesor]+1;
                                                        }
                                                    }
                                                }
                                            }else
                                            if(_status === ""){
                                                for(let i in resp.data.data){
                                                    if(resp.data.data[i].email === asesor){
                                                        if(typeof _data[asesor] === "undefined"){
                                                            _data[asesor]=1
                                                        }
                                                        else{
                                                            _data[asesor]=_data[asesor]+1;
                                                        }
                                                    }
                                                }
                                            }
                                        }else
                                        if((startDate !== "" && endDate==="") || (startDate === "" && endDate!=="") ){
                                            alert("Escoja la fecha")
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                for(let i in resp.data.data){
                    if(typeof _data[resp.data.data[i].email] !=="undefined"){
                        let temp={
                            email: resp.data.data[i].email,
                            name: resp.data.data[i].name,
                            count:_data[resp.data.data[i].email],
                            project:select
                        }
                        _dataTable.push(temp)
                        setDataTable({
                            columns: [
                            {
                                label: 'Proyecto Actual',
                                field: 'project',
                                width: 150,
                                attributes: {
                                'aria-controls': 'DataTable',
                                'aria-label': 'Name',
                                },
                            },
                            
                            {
                                label: 'Correo',
                                field: 'email',
                                sort: 'disabled',
                                width: 150,
                            },
                            {
                                label: 'Nombre',
                                field: 'name',
                                sort: 'asc',
                                width: 100,
                            },
                            {
                                label: 'Conversaciones Asignadas',
                                field: 'count',
                                sort: 'disabled',
                                width: 150,
                            },
                            ],
                            rows: _dataTable,
                        }) 
                    }
                }
                for(let data in response.json){
                    for(let x in response.json[data]){
                        if(x !== "its_mine" && response.json[data]['its_mine'].toLowerCase() !== select.toLowerCase()){
                            for(let y in response.json[data][x]){
                                if(Object.keys(response.json[data]).length >= 3 && x.toLowerCase() === select.toLowerCase()){
                                    if(typeof response.json[data][x][y][response.json[data][x][y].its_mine] !== "undefined"){
                                        let asesor = response.json[data][x][y][response.json[data][x][y].its_mine].assessor
                                        const status = await axios.post(`http://52.73.114.253:3003/api/v1/reports/twilio`,{
                                            "sid":response.json[data][x][y][response.json[data][x][y].its_mine].sid
                                        });
                                        var timestamp=response.json[data][x][y][response.json[data][x][y].its_mine].time
                                        var date = new Date(timestamp);
                                        var startDates= new Date(startDate);
                                        var endDates= new Date(endDate);
                                        if(startDates > endDates){
                                            alert("La fecha de fin tiene que ser mayor")
                                        }else
                                        if((startDates <= date && endDates>=date)||(startDate==="" && endDate==="")){
                                            if(_status === status.data.estado){
                                                for(let i in resp.data.data){
                                                    if(resp.data.data[i].email === asesor){
                                                        if(typeof _data2[asesor] === "undefined"){
                                                            _data2[asesor]=1
                                                        }
                                                        else{
                                                            _data2[asesor]=_data[asesor]+1;
                                                        }
                                                    }
                                                }
                                            }else
                                            if(_status === ""){
                                                for(let i in resp.data.data){
                                                    if(resp.data.data[i].email === asesor){
                                                        if(typeof _data2[asesor] === "undefined"){
                                                            _data2[asesor]=1
                                                        }
                                                        else{
                                                            _data2[asesor]=_data[asesor]+1;
                                                        }
                                                    }
                                                }
                                            }
                                        }else
                                        if((startDate !== "" && endDate==="") || (startDate === "" && endDate!=="") ){
                                            alert("Escoja la fecha")
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                for(let i in resp.data.data){
                    if(typeof _data2[resp.data.data[i].email] !=="undefined"){
                        let temp={
                            email: resp.data.data[i].email,
                            name: resp.data.data[i].name,
                            count:_data2[resp.data.data[i].email],
                            project:select
                        }
                        _dataTable2.push(temp)
                        setDataTable2({
                            columns: [
                            {
                                label: 'Proyecto Actual',
                                field: 'project',
                                width: 150,
                                attributes: {
                                'aria-controls': 'DataTable',
                                'aria-label': 'Name',
                                },
                            },
                            
                            {
                                label: 'Correo',
                                field: 'email',
                                sort: 'disabled',
                                width: 150,
                            },
                            {
                                label: 'Nombre',
                                field: 'name',
                                sort: 'asc',
                                width: 100,
                            },
                            {
                                label: 'Conversaciones Asignadas',
                                field: 'count',
                                sort: 'disabled',
                                width: 150,
                            },
                            ],
                            rows: _dataTable,
                        }) 
                    }
                    
                }
            },
        );
            
    }
  
    useEffect(() => {
        var objeto=[]
        let c=0
        Utils.Petition.post(
            '/reports/conversationsbyinbox',{
                "project":"urbansa"
            },
            response=> {
                let option=[]
                for(let data in response.json){
                    let its_mine=response.json[data]['its_mine']
                    its_mine=its_mine.toLowerCase().charAt(0).toUpperCase() + its_mine.slice(1)
                    if(option[its_mine] !== "a"){
                        option[its_mine]="a"
                        objeto.push({
                            id:its_mine,
                            name:its_mine,
                            active:true
                        })
                    }
                }
                setList(objeto)
            },
        );

    },[]);

    return (
        <>
           <div className="card shadow mb-4">
                <div className="card-header">
                    Ver reporte
                </div>
                <div className="card-body">
                    <form className="form-inline" onSubmit={handleSubmit}>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="reportId" >Proyecto</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select required className="form-control" id="reportId"  >
                                {list.map((item, i) =>
                                    <option key={i} value={item.id} disabled={!item.active}>{item.name}</option>
                                )};
                            </select>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="startDate" >Estado</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select className="form-control" id="estado"  >
                                <option value=""></option>
                                <option value="abierto">Abierto</option>
                                <option value="cerrado">Cerrado</option>
                            </select>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="startDate" >Inicio</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <input type="date"  id="startDate"   className="form-control"/>
                        </div>

                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="endDate" >Fin</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <input type="date"  id="endDate"  className="form-control"/>
                        </div>
                        <button type="submit" className="btn btn-info mb-2">Consultar</button>
                    </form>
                </div>
            </div>
            <div className="card shadow mb-4">
                {
                    dataTable &&
                        <div className="card-header" onClick={handleShow1}>
                            Propios
                        </div>
                }
                {
                    showCard1 &&
                    <div className="card-body">
                        {
                            dataTable && filterProject && 
                            <>
                                <h5>{filterProject} - Propios</h5>  
                                <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={dataTable} searchTop searchBottom={false}/>
                            </>
                        }
                    </div>
                }
            </div>
            <div className="card shadow mb-4">
                {
                    dataTable2 &&
                        <div className="card-header" onClick={handleShow2}>
                            No Propios
                        </div>
                }
                {
                    showCard2 &&
                    <div className="card-body">
                        {
                            dataTable2 && filterProject &&
                            <>
                            <h5>{filterProject} - No Propios</h5>  
                                <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={dataTable2} searchTop searchBottom={false}/>
                            </>
                        }
                    </div>
                }
                
            </div>
        </>
    );
};

export default ReportSells;