import React, {useEffect, useState} from 'react';
import ReactFileReader from 'react-file-reader';
import Utils from '../../../utils';
function SendMessages({changeTitle}){
    const [infoMessage, setInfoMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(false);
    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState('');
    const [selectFile, setSelectFile] = useState('');
    const [template, setTemplate] = useState('');
    const [namespace, setNamespace] = useState('');
    const [language, setLanguage] = useState('');
    const [messageLoadingCSV, setMessageLoadingCSV] = useState('');
    const [optionsTypeMessage, setOptionsTypeMessage] = useState([]);
    const [optionsFiles, setOptionsFiles] = useState([]);
    const [table, setTable] = useState([]);
    const [db, setDb] = useState(null);
    const [requireMedia, setRequireMedia] = useState(false);

    const [realTimeInfo, setRealTimeInfo] = useState({

        messageType: "",
        selectFile: "",
        template: "",
        inputFile: "",
        language: "",
        namespace: "",

    })

    
    
    const handleSubmit = function(event){
        event.preventDefault();
        setMessage('');
        if(!messageLoadingCSV) setMessage('Por favor selecciona una base de datos');
        else{
            setRealTimeInfo({...realTimeInfo, company:localStorage.getItem('company')})
            setPreview(true);
        }

    }
    const handleTypeMessage = function(event){
        setMessage('');
        const _target = event.target;
        if(_target.options[_target.selectedIndex].getAttribute('data-code')!=='MTS') setRequireMedia(true);
        
        
        else{
            setRequireMedia(false);
            document.getElementById('select-file').value = '';
        } 
        setTypeMessage(_target.options[_target.selectedIndex].value);
        setRealTimeInfo({...realTimeInfo, messageType:_target.options[_target.selectedIndex].innerText} );
    }
    const handleSelectFile = function(event){
        setMessage('');
        const _target = event.target;
        setSelectFile(_target.options[_target.selectedIndex].value);
        setRealTimeInfo({...realTimeInfo, selectFile:_target.options[_target.selectedIndex].innerText} );
    }
    
    const handleTemplate = event => {
        setTemplate( event.target.value);
        setRealTimeInfo({...realTimeInfo, template:event.target.value} );
    }
    const handleLanguage = event=>{
        setLanguage( event.target.value);
        setRealTimeInfo({...realTimeInfo, language:event.target.value} );
    }
    const handleNamespace = event=>{
        setNamespace( event.target.value);
        setRealTimeInfo({...realTimeInfo, namespace:event.target.value} );
    }
    const handleFiles = files => {
        setMessage('');
        setMessageLoadingCSV('Cargando archivo...');
        var reader = new FileReader();
        reader.onload = function(e) {
            const tr = reader.result.split('\n');
            let rows = [];
            for(let i in tr){
                rows.push(tr[i].split(','));
            }
            if(rows.length>6){
                const copy = rows.splice(0,6);
                setTable(copy);
            }else{
                setTable(rows);
            }
            setMessageLoadingCSV(files[0].name);
            setRealTimeInfo({...realTimeInfo, inputFile:files[0].name} );
        }
        setDb(files[0]);
        reader.readAsText(files[0]);
    }

    useEffect(() => {
        changeTitle('Enviar mensajes');
        Utils.Petition.get(
            '/services',
            (response)=>{
                setOptionsTypeMessage(response.data);
                setTypeMessage(response.data[0]._id);
                setRealTimeInfo({...realTimeInfo, messageType: response.data[0].name} );
            }
        )
        Utils.Petition.get(
            `/files/${localStorage.getItem('company')}`,
            (response)=>{
                setOptionsFiles(response.data);
                setSelectFile(response.data[0]._id);
                setRealTimeInfo({...realTimeInfo, selectFile: response.data[0].name} );
            }
        )
    }, []);
    const handleGoBack = function(){
        setPreview(false);
    }

    const handleConfirmSubmit = function(){
       setLoading(true);
       setInfoMessage(null);

        Utils.Petition.postFile(
            '/messages/send',
            {
                user: localStorage.getItem('company'),
                service: typeMessage,
                media:selectFile,
                template,
                namespace,
                language,
                db
            },
            response=>{
                console.log(response);
                
                setPreview(false);
                setTemplate('');
                setLanguage('');
                setNamespace('');
                setMessageLoadingCSV('');
                setInfoMessage({sent:response.data.sent,noSent:response.data.noSent});
                


            },
            err=> console.log(err,err.response),
            ()=> setLoading(false)
        );
        
    }

    return (
        <div className="card shadow mb-4">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-5">
                        {loading && <div className="loading"></div>}
                        {
                            infoMessage && 
                            <div id="sent-result" className={`alert ${infoMessage ? "alert-info" : ""}`}>
                                Resultado del envío:
                                <ul>
                                    <li><strong>{infoMessage.sent}</strong> mensajes enviados</li>
                                    <li><strong>{infoMessage.noSent}</strong> mensajes no enviados</li>
                                </ul>
                            </div>
                        }
                        <form onSubmit={handleSubmit}>
                            { preview && <div className="disable-div"></div> }
                            
                            <div className="form-group">
                                <label htmlFor="select-type-message">Tipo de mensaje</label>
                                <select required id="select-type-message" className="form-control" onChange={handleTypeMessage}>
                                    {
                                        optionsTypeMessage.map(item =>
                                            <option key={item._id} data-code={item.code} value={item._id}>{item.name}</option>
                                        )
                                    };
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="select-file">Archivo</label>
                                <select id="select-file" className="form-control" disabled={!requireMedia} required={requireMedia} onChange={handleSelectFile}>
                                    <option value="">Seleccionar archivo</option>
                                    {
                                        optionsFiles.map( item =>
                                            <option key={item._id} value={item._id}>{item.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="template">Plantilla</label>
                                <input type="text" required className="form-control" value={template} id="template" name="template" onChange={handleTemplate} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="namespace">Namespace</label>
                                <input type="text" required className="form-control" value={namespace} id="namespace" name="namespace" onChange={handleNamespace} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="language">Idioma</label>
                                <input type="text" required className="form-control" value={language} id="language" name="language" onChange={handleLanguage} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="db">Base de datos</label>
                                <ReactFileReader elementId="file-db" required handleFiles={handleFiles} fileTypes={'.csv'}>
                                    <button type="button" className="btn btn-info">Subir archivo CSV</button>
                                </ReactFileReader>
                                {messageLoadingCSV}
                            </div>
                            <div className={`alert ${message ? "alert-danger" : ""}`}>{message}</div>
                            <div className="form-group">
                                <button className="btn btn-info">Enviar</button>
                            </div>
                        </form>
                    </div>
                        
                    <div className="col-md-7 align-items-center">
                        {
                            preview &&
                            <>
                                <h5>Verifica la información</h5>
                                <hr />
                                <b>Tipo de mensaje:</b> {realTimeInfo.messageType}<br />
                                <b>Archivo seleccionado:</b> {realTimeInfo.selectFile}<br />
                                <b>Plantilla:</b> {realTimeInfo.template}<br />
                                <b>Namespace:</b> {realTimeInfo.namespace}<br /> 
                                <b>Idioma:</b> {realTimeInfo.language}<br />
                                <b>Archivo de base de datos:</b> {realTimeInfo.inputFile}
                                <hr />
                                <b>Previsualización de los datos (primeros 5)</b>
                                <div className="o-auto">
                                    { 
                                        table.length > 0 && 
                                        <table className="table table-bordered small-p">
                                            <thead className="thead-dark">
                                                <tr>
                                                    {table[0].map((item, i) =>
                                                        <th key={i} scope="col">{item}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {table.map((item, i) =>
                                                        i !== 0 &&
                                                        <tr key={i}>
                                                            {table[i].map((subItem, j)=>
                                                                <td key={i+'_'+j}>{subItem}</td>
                                                            )}
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                                <div className={`alert ${message ? "alert-danger" : ""}`}>{message}</div>
                                <div className="form-group">
                                    <button className="btn btn-danger" onClick={handleGoBack}>Volver</button> &nbsp; 
                                    <button className="btn btn-info" onClick={handleConfirmSubmit}>Confirmar carga</button>
                                </div>

                            </>
                        }
                    </div>
                        
                </div>
                
            </div>
            
        </div>
    );
}
export default SendMessages;