import React from 'react';
import { FaAngleUp } from 'react-icons/fa';
import Menu from '../menu/Menu';
import Topbar from '../topbar/Topbar';
import Container from '../container/Container';
import Footer from '../footer/Footer';

function Dashboard({handleCloseSession, company, setCompany}) {
    return (
        <>
            <div id="wrapper">

                <Menu />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Topbar handleCloseSession={handleCloseSession} company={company} setCompany={setCompany} />
                        <div className="container-fluid">
                            <Container />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <FaAngleUp />
            </a>
        </>
    );
}
export default Dashboard;