import React, {useEffect, useState} from 'react';
import { FaRegComments } from 'react-icons/fa';
import {Link,useHistory} from 'react-router-dom';


function Menu() {
    let history = useHistory();

    function handleChange(value) {
        history.push(`${value}`);
    }
    const [menu,setMenu] = useState(eval(localStorage.getItem('menu')));
    return (
        <ul className="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">
            <a className="shadow sidebar-brand d-flex align-items-center justify-content-center" href="/">
                {/* 
                    <div className="sidebar-brand-icon rotate-n-15">
                        <FaRegComments />
                    </div>
                */}
                <div className="sidebar-brand-text mx-3">Talks pro</div>
            </a>
            <hr className="sidebar-divider mb-1-5 mt-0" />

            

            {/* <hr className="sidebar-divider" />

            <div className="sidebar-heading">
                Configuración
            </div>

            <li className="nav-item">
                <Link to="/dashboard/channels-bots">Canales/Bots</Link>
            </li>
            <li className="nav-item">
                <Link to="/dashboard/agents">Gestión de agentes</Link>
            </li> */}

            <hr className="sidebar-divider" />
            <div className="sidebar-heading">
                Reportes
            </div>
            
            <select name="select" className="form-control" onChange={event => handleChange(event.target.value)}>
                <option value=""></option>
                {
                    menu &&
                    menu[0].reportes.map((item, i) =>
                        <option value={item.link}>{item.name}</option>
                    )
                }
            </select>
            {/* {
                menu &&
                menu[0].reportes.map((item, i) =>
                    
                    <li className="nav-item">
                        <Link to={item.link}>{item.name}</Link>
                    </li>
                )
            } */}
            <hr className="sidebar-divider" />

        </ul>
    );
}

export default Menu;