import React, {useEffect} from 'react';

function Start({changeTitle}){

    useEffect(() => {
        changeTitle('Bienvenido');
    },[]);

    return (
        <div className="row">
            <div className="col">
                
            </div>
        </div>
        
    );
}
export default Start;