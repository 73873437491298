import React, { useState } from 'react';
import Utils from '../../utils';
import {VERSION_SESSION} from '../../config';
import './LoginForm.css';

function LoginForm({changeLogin}){

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState('');
    const [message, setMessage] = useState('');

    const handleChangeEmail = (event) => {
        setEmail( event.target.value);
    }
    const handleChangePassword = (event) => {
        setPassword( event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setMessage('');
        Utils.Petition.publicPost(
            "/users/login",
            {
                "email":email,
                "password":password
            },
            (result)=>{
                localStorage.setItem('token_user', result.token);
                localStorage.setItem('VERSION_SESSION', VERSION_SESSION);

                Utils.Petition.get(
                    '/users/current',
                    response=> {
                        localStorage.setItem('company', response.data.user._id);
                        localStorage.setItem('menu', response.data.rol.permisos);
                        localStorage.setItem('firstname', response.data.user.firstname);
                        changeLogin(true);
                    },
                )
                
            },
            (err)=>{
                setMessage('Correo y/o contraseña inválidos');
            },
            ()=>{
                setLoading(false);
            }
        );
    }

    return (
        <div className="login-form">
            <div className="container">
                <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card card-signin my-5">
                            <div className="card-body">
                                <form className="form-signin" onSubmit={handleSubmit}>
                                {
                                    loading && <div className="loading"></div>
                                }
                                    <h5>TALKSPRO</h5>
                                    <div className="form-group">
                                        <label htmlFor="inputEmail">Correo</label>
                                        <input type="email" id="inputEmail" className="form-control" placeholder="example@gmail.com" required autoFocus value={email} onChange={handleChangeEmail} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="inputPassword">Contraseña</label>
                                        <input type="password" id="inputPassword" className="form-control" placeholder="********" required value={password} onChange={handleChangePassword} />
                                    </div>
                                    
                                    <div className={`alert ${message ? "alert-danger" : ""}`}>{message}</div>
                                    <button className="btn btn-lg btn-info btn-block text-uppercase" type="submit">Iniciar sesión</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;