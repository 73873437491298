import React, { useState } from 'react';
import {Route} from 'react-router-dom';
import Start from './components/Start';
import SendMessages from './components/SendMessages';
import AdminFiles from './components/AdminFiles';
import ChannelsBots from './components/ChannelsBots';
import Agents from './components/Agents';
import Reports from './components/Reports';
import ReportStart from './components/ReportStart';
import ReportResponse from './components/ReportResponse.js';
import Graphics from './components/Graphics';
import ReportEnd from './components/ReportEnd';
import ReportIngresos from './components/ReportIngresos';
import ReportSells from './components/ReportSells';
import ReportActivity from './components/ReportActivity';


function Container(){
    const [title, setTitle] = useState('');
    return (
        <div id="page-top">

            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{title}</h1>
            </div>
            <div className="text-left">
                <Route path="/dashboard" exact component={() => <Start changeTitle={setTitle} />} />
                <Route path="/dashboard/send-messages" component={() => <SendMessages changeTitle={setTitle} />} />
                <Route path="/dashboard/admin-files" component={() => <AdminFiles changeTitle={setTitle} />} />
                <Route path="/dashboard/channels-bots" component={() => <ChannelsBots changeTitle={setTitle} />} />
                <Route path="/dashboard/agents" component={() => <Agents changeTitle={setTitle} />} />
                <Route path="/dashboard/reports" component={() => <Reports changeTitle={setTitle} />} />
                <Route path="/dashboard/reports_start" component={() => <ReportStart changeTitle={setTitle} />} />
                <Route path="/dashboard/reports_a" component={() => <ReportResponse changeTitle={setTitle} />} />
                <Route path="/dashboard/graphics" component={() => <Graphics changeTitle={setTitle} />} />
                <Route path="/dashboard/activityend" component={() => <ReportEnd changeTitle={setTitle} />} />
                <Route path="/dashboard/activity" component={() => <ReportActivity changeTitle={setTitle} />} />
                <Route path="/dashboard/ingresos" component={() => <ReportIngresos changeTitle={setTitle} />} />
                <Route path="/dashboard/sells" component={() => <ReportSells changeTitle={setTitle} />} />
            </div>
        </div>
    );
}

export default Container;