import React, {useEffect, useState} from 'react';
import {FaEye} from 'react-icons/fa';
import Utils from '../../../utils';
import { MDBDataTableV5 } from 'mdbreact';


function ReportStart({changeTitle}){
    const [form, setForm] = useState({
        startDate: '',
        endDate:'',
        reportId:'1'
    });
    const [dataTable,setDataTable] = useState();
    const [dataTable2,setDataTable2] = useState();
    const [filterProject,setFilterProject] = useState();
    const [selected, setSelected] = useState(null);
    const [list, setList] = useState([]);
    const [showCard1, setShowCard1] = useState(false);
    const [showCard2, setShowCard2] = useState(false);

    const handleShow1 = function(e){
        if(showCard1){setShowCard1(false);}else{setShowCard1(true);}
    }
    const handleShow2 = function(e){
        if(showCard2){setShowCard2(false);}else{setShowCard2(true); }
    }
    const handleSubmit = function(event){
        event.preventDefault();
        let object = []
        let object2 = []
        Utils.Petition.post(
            '/reports/conversationsbyinbox',{
                "project":"urbansa"
            },
            response=> {
                let select = document.getElementById("reportId").value;
                let startDate = document.getElementById("startDate").value;
                let endDate = document.getElementById("endDate").value;
                let _status = document.getElementById("estado").value;
                let _dataTable = [];
                let _dataTable2 = [];
                setDataTable()
                setDataTable2()
                setFilterProject(select)
                for(let data in response.json){
                    for(let x in response.json[data]){
                        if(x !== "its_mine" && response.json[data]['its_mine'].toLowerCase() === select.toLowerCase()){
                            for(let y in response.json[data][x]){
                                if(x.toLowerCase() === select.toLowerCase()){
                                    Utils.Petition.post(
                                        '/reports/starts',{
                                            "sid":response.json[data][x][y][response.json[data][x][y].its_mine].sid
                                        },status=> {
                                            var timestamp=response.json[data][x][y][response.json[data][x][y].its_mine].time
                                            var date = new Date(timestamp);
                                            var startDates= new Date(startDate);
                                            var endDates= new Date(endDate);
                                            var cleanDate=date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
                                            if(startDates > endDates){
                                                alert("La fecha de fin tiene que ser mayor")
                                            }else
                                            if((startDates <= date && endDates>=date && status.response === "true" )||(startDate==="" && endDate==="" && status.response === "true")){

                                                let temp={
                                                    assessor: response.json[data][x][y][response.json[data][x][y].its_mine].assessor,
                                                    sid: response.json[data][x][y][response.json[data][x][y].its_mine].sid,
                                                    time: cleanDate,
                                                    starts:status.date,
                                                    status:status.estado,
                                                    project:select
                                                }
                                                if(_status === status.estado){
                                                    _dataTable.push(temp)
                                                }else
                                                if(_status === ""){
                                                    _dataTable.push(temp)
                                                }
                                                
                                            }else
                                            if((startDate !== "" && endDate==="") || (startDate === "" && endDate!=="") ){
                                                alert("Escoja la fecha")
                                            }
                                            setDataTable({
                                                columns: [
                                                {
                                                    label: 'Proyecto Actual',
                                                    field: 'project',
                                                    width: 150,
                                                    attributes: {
                                                    'aria-controls': 'DataTable',
                                                    'aria-label': 'Name',
                                                    },
                                                },
                                                {
                                                    label: 'Fecha de creación',
                                                    field: 'time',
                                                    width: 270,
                                                },
                                                {
                                                    label: 'Tiempo de respuesta',
                                                    field: 'starts',
                                                    width: 270,
                                                },
                                                {
                                                    label: 'ID Conversacion',
                                                    field: 'sid',
                                                    width: 200,
                                                },
                                                {
                                                    label: 'Estado',
                                                    field: 'status',
                                                    sort: 'asc',
                                                    width: 100,
                                                },
                                                {
                                                    label: 'Asesor Actual',
                                                    field: 'assessor',
                                                    sort: 'disabled',
                                                    width: 150,
                                                },
                                                ],
                                                rows: _dataTable,
                                            }) 
                                           
                                    })
                                    
                                }
                            }
                        }
                    }
                }

                for(let data in response.json){
                    for(let x in response.json[data]){
                        if(x !== "its_mine" && response.json[data]['its_mine'].toLowerCase() !== select.toLowerCase()){
                            for(let y in response.json[data][x]){
                                if(Object.keys(response.json[data]).length >= 3 && x.toLowerCase() === select.toLowerCase()){
                                    var _its_mine = response.json[data].its_mine;
                                    for(let i in response.json[data][_its_mine]){
                                        var actualAssessor = response.json[data][_its_mine][i][response.json[data][_its_mine][i].its_mine].assessor
                                    }
                                    Utils.Petition.post(
                                    '/reports/starts',{
                                        "sid":response.json[data][x][y][response.json[data][x][y].its_mine].sid
                                    },status=> {
                                        var timestamp=response.json[data][x][y][response.json[data][x][y].its_mine].time
                                        var date = new Date(timestamp);
                                        var startDates= new Date(startDate);
                                        var endDates= new Date(endDate);
                                        
                                        var cleanDate=date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
                                        if(startDates > endDates){
                                            alert("La fecha de fin tiene que ser mayor")
                                        }else
                                        if((startDates <= date && endDates>=date && status.response === "true" )||(startDate==="" && endDate==="" && status.response === "true")){
                                            var temp={
                                                assessor: response.json[data][x][y][response.json[data][x][y].its_mine].assessor,
                                                sid: response.json[data][x][y][response.json[data][x][y].its_mine].sid,
                                                time: cleanDate,
                                                starts:status.date,
                                                status:status.estado,
                                                project:_its_mine,
                                                actual: actualAssessor,
                                                lastproject:select
                                            }
                                            if(_status === status.estado){
                                                _dataTable2.push(temp)
                                            }else
                                            if(_status === ""){
                                                _dataTable2.push(temp)
                                            }
                                        }else
                                        if((startDate !== "" && endDate==="") || (startDate === "" && endDate!=="") ){
                                            alert("Escoja la fecha")
                                        }
                                        setDataTable2({
                                            columns: [
                                            {
                                                label: 'Proyecto Anterior',
                                                field: 'lastproject',
                                                width: 150,
                                                attributes: {
                                                    'aria-controls': 'DataTable',
                                                    'aria-label': 'project',
                                                },
                                            },
                                            {
                                                label: 'Proyecto Actual',
                                                field: 'project',
                                                width: 150
                                            },
                                            {
                                                label: 'Fecha de creación',
                                                field: 'time',
                                                width: 270,
                                            },
                                            {
                                                label: 'Tiempo de respuesta',
                                                field: 'starts',
                                                width: 270,
                                            },
                                            {
                                                label: 'ID Conversacion',
                                                field: 'sid',
                                                width: 200,
                                            },
                                            {
                                                label: 'Estado',
                                                field: 'status',
                                                sort: 'asc',
                                                width: 100,
                                            },
                                            {
                                                label: 'Asesor Actual',
                                                field: 'actual',
                                                sort: 'disabled',
                                                width: 150,
                                            },
                                            {
                                                label: 'Asesor Anterior',
                                                field: 'assessor',
                                                sort: 'disabled',
                                                width: 150,
                                            },
                                            ],
                                            rows: _dataTable2,
                                        })   
                                    })
                                }
                            }
                        }
                    }
                }
            },
        );
            
    }
  
    const handleForm = function(event){
        setForm({...form, [event.target.id]:  event.target.value });
    }

    useEffect(() => {
        var objeto=[]
        let c=0
        Utils.Petition.post(
            '/reports/conversationsbyinbox',{
                "project":"urbansa"
            },
            response=> {
                let option=[]
                for(let data in response.json){
                    let its_mine=response.json[data]['its_mine']
                    its_mine=its_mine.toLowerCase().charAt(0).toUpperCase() + its_mine.slice(1)
                    if(option[its_mine] !== "a"){
                        option[its_mine]="a"
                        objeto.push({
                            id:its_mine,
                            name:its_mine,
                            active:true
                        })
                    }
                }
                setList(objeto)
            },
        );

    },[]);

    return (
        <>
           <div className="card shadow mb-4">
                <div className="card-header">
                    Ver reporte
                </div>
                <div className="card-body">
                    <form className="form-inline" onSubmit={handleSubmit}>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="reportId" >Proyecto</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select required className="form-control" id="reportId" onChange={handleForm} >
                                {list.map((item, i) =>
                                    <option key={i} value={item.id} disabled={!item.active}>{item.name}</option>
                                )};
                            </select>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="startDate" >Estado</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <select className="form-control" id="estado" onChange={handleForm} >
                                <option value=""></option>
                                <option value="abierto">Abierto</option>
                                <option value="cerrado">Cerrado</option>
                            </select>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="startDate" >Inicio</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <input type="date"  id="startDate" onChange={handleForm}  className="form-control"/>
                        </div>

                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="endDate" >Fin</label>
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <input type="date"  id="endDate" onChange={handleForm} className="form-control"/>
                        </div>
                        <button type="submit" className="btn btn-info mb-2">Consultar</button>
                    </form>
                </div>
            </div>
            <div className="card shadow mb-4">
                {
                    dataTable &&
                        <div className="card-header" onClick={handleShow1}>
                            Propios
                        </div>
                }
                {
                    showCard1 &&
                    <div className="card-body">
                        {
                            dataTable && filterProject && 
                            <>
                                <h5>{filterProject} - Propios</h5>  
                                <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={dataTable} searchTop searchBottom={false}/>
                            </>
                        }
                    </div>
                }
            </div>
            <div className="card shadow mb-4">
                {
                    dataTable2 &&
                        <div className="card-header" onClick={handleShow2}>
                            No Propios
                        </div>
                }
                {
                    showCard2 &&
                    <div className="card-body">
                        {
                            dataTable2 && filterProject &&
                            <>
                            <h5>{filterProject} - No Propios</h5>  
                                <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={dataTable2} searchTop searchBottom={false}/>
                            </>
                        }
                    </div>
                }
                
            </div>
        </>
    );
};

export default ReportStart;